import React from "react";
import hint from "../../../assets/sso/Hint.svg";

const Help: React.FC = () => {
    const url = "https://www.calameo.com/read/006173559fc8f115c9c21";

    const openLink = () => {
        window.open(url, "_blank");
    }

    return <div className="help-box">
        <div className="box-title">
            <img src={hint} alt="hint icon"/>
            <div className="title-help">Besoin d'aide ?</div>
        </div>
        <button onClick={openLink} className="btn btn-green btn-hint">Voir le guide d'utilisation</button>
    </div>
}

export default Help;