import React, {ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState} from "react";
import Navbar from "../navbar/Navbar";
import './administration.css';
import axios from "axios";
import iconGroup from "../assets/Icongroup.svg";
import iconAdmin from "../assets/Iconadmin.svg";
import setting from "../assets/setting.svg";
import Pagination from "../pagination/Pagination";
import deleteIcon from "../assets/delete.svg";
import resetPassword from "../assets/mdp_restore.svg";

interface RegisterFormData {
    email: string;
    password: string;
    groupement: {
        id: string;
        [key: string]: any;
    };
}

function Administration() {
    const [isGroupOpen, setIsGroupOpen] = useState(false);
    const [isAdminOpen, setIsAdminOpen] = useState(false);
    const [isSettingAdminOpen, setIsSettingAdminOpen] = useState(false);
    const [isSettingGroupOpen, setIsSettingGroupOpen] = useState(false);
    const [groupement, setGroupement] = useState<any[]>([]);
    const [admins, setAdmins] = useState<any[]>([]);
    const [name, setName] = useState('');
    const [isGlobal, setIsGlobal] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');
    const [openFlash, setOpenFlash] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [formData, setFormData] =
        useState<RegisterFormData>({
            email: '',
            password: '',
            groupement: {
                id: '',
            },
        });
    const isFetching = useRef(false);
    let itemsPerPage = 3;

    const handleGroupHeaderClick = () => {
        setIsGroupOpen(!isGroupOpen);
        setIsAdminOpen(false);
        setIsSettingAdminOpen(false);
        setIsSettingGroupOpen(false);
    }

    const handleAdminHeaderClick = () => {
        setIsAdminOpen(!isAdminOpen);
        setIsGroupOpen(false);
        setIsSettingAdminOpen(false);
        setIsSettingGroupOpen(false);
    }

    const handleSettingAdminHeaderClick = () => {
        setIsSettingAdminOpen(!isSettingAdminOpen);
        setIsGroupOpen(false);
        setIsAdminOpen(false);
        setIsSettingGroupOpen(false);
    }

    const handleSettingGroupHeaderClick = () => {
        setIsSettingGroupOpen(!isSettingGroupOpen);
        setIsGroupOpen(false);
        setIsAdminOpen(false);
        setIsSettingAdminOpen(false);
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        if (name === 'groupement') {
            setFormData(prev => ({ ...prev, groupement: { id: value } }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }    };

    const handleCreateGroupement = async (e: FormEvent) => {
        e.preventDefault();
        const resp = await axios.post('/api/groupements', {
            name: name,
            isGlobal: isGlobal,
        }, {
            headers: {
                'Content-Type': 'application/ld+json'
            }
        });

        if (resp.status === 201) {
            setName('');
            setIsGlobal(false);
            setOpenFlash(true);
            setFlashMessage('Groupement créé avec succès');
            fetchGroupement();
            setTimeout(() => {
                setOpenFlash(false);
                setFlashMessage('');
            }, 8000);
        } else {
            setOpenFlash(true);
            setFlashMessage('Echec de création du groupement');
            setTimeout(() => {
                setOpenFlash(false);
                setFlashMessage('');
            }, 8000);
        }
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/register', {
                email: formData.email,
                password: formData.password,
                groupement: `/api/groupements/${formData.groupement.id}`,
            });

            if (response.data.message) {

            }
        } catch (error) {
            console.error('Error registering user:', error);
        }
    }

    const handleDelete = async (id: string) => {
        try {
            await axios.delete(`/api/groupements/${id}`);
            fetchGroupement();
        } catch (error) {
            console.error('Error when deleting groupement : ' + error);
        }
    }

    const handleDeleteAdmin = async (id: string) => {
        try {
            await axios.delete(`/api/user_admins/${id}`);
            fetchAdmins();
        } catch (error) {
            console.error('Error when deleting user Admin : ' + error);
        }
    }

    const handleResetPassword = async (email: string) => {
        try {
            const formData = new FormData();
            formData.append('email', email);

            await axios.post('/reset-password', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (error) {
            console.error('Error when refresh password request ' + error);
        }
    }

    const fetchGroupement = async () => {
        try {
            const response = await axios.get('/api/groupements');
            setGroupement(response.data['hydra:member']);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchGroupement();
    }, []);

    const fetchAdmins = useCallback(async () => {
        if (isFetching.current) {
            return;
        }

        try {
            isFetching.current = true;
            const response = await axios.get(
                `/api/user_admins?itemsPerPage=${itemsPerPage}&page=${currentPage}`
            );
            setAdmins(response.data['hydra:member']);
            setTotalItems(response.data['hydra:totalItems']);
            const total = (Math.ceil(totalItems / itemsPerPage));
            setTotalPage(total > 0 ? total : 1);
        } catch (error) {
            console.error('Error when fetching Admins : ' + error);
        } finally {
            isFetching.current = false;
        }
    }, [currentPage, itemsPerPage, totalItems]);

    useEffect(() => {
        fetchAdmins();
    }, [fetchAdmins]);

    return (
        <>
            <div className="container">
                <div className="col-4">
                    <Navbar />
                </div>
                <div className="col-8">
                    <div className="right-panel">
                        <div className="right-panel-container">
                            <div className="title-bar">
                                <div className="main-title">
                                    <h1>Administration</h1>
                                    <p>Vous avez la possibilité d’administrer les groupements et administrateurs.</p>
                                </div>
                            </div>
                            <div key="groupement" className="card-new-publication">
                                <div className="card-header">
                                    <h2
                                        className={'no-arrow'}
                                        onClick={handleGroupHeaderClick}
                                    >
                                        <img
                                            src={iconGroup}
                                            style={{
                                                width: 32,
                                                height: 32,
                                            }}
                                            alt="logo groupement"/>
                                        Créer un groupement
                                    </h2>
                                </div>
                                <div>
                                    {isGroupOpen && (
                                        <>
                                            <form onSubmit={handleCreateGroupement}>
                                                <label htmlFor="name">{`Nom du groupement`}</label>
                                                <div className="group-groupement">
                                                    <input
                                                        className={"mid-input"}
                                                        type="text"
                                                        name="name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        placeholder={`Immotep`}
                                                        required
                                                    />
                                                    <label htmlFor="isGlobal">Global</label>
                                                    <input
                                                        type="radio"
                                                        name="isGlobal"
                                                        checked={isGlobal}
                                                        onClick={(e) => setIsGlobal(!isGlobal)}
                                                    />
                                                    <button className={"btn btn-block add-circle"}> Créer un
                                                        groupement
                                                    </button>
                                                </div>
                                            </form>
                                            {openFlash && (
                                                <p>{flashMessage}</p>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div key="new-admin" className="card-new-publication">
                                <div className="card-header">
                                    <h2
                                        className={'no-arrow'}
                                        onClick={handleAdminHeaderClick}
                                    >
                                        <img
                                            src={iconAdmin}
                                            style={{
                                                width: 32,
                                                height: 32,
                                            }}
                                            alt="logo publication"/>
                                        Créer un administrateur
                                    </h2>
                                </div>
                                <div className="group-login">
                                    {isAdminOpen && (
                                        <form onSubmit={handleSubmit} className="login">
                                            <div className="form-group-login">
                                                <div className="group">
                                                    <label htmlFor="email">Email</label>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        value={formData.email}
                                                        className="form-control admin-email"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="group">
                                                    <label htmlFor="password">Mot de passe</label>
                                                    <input
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        className="form-control admin-password"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="group">
                                                <label htmlFor="groupement">Groupement</label>
                                            </div>
                                            <div className="group-groupement">
                                                <div className="group">
                                                    <select
                                                        name={"groupement"}
                                                        className={"admin-group"}
                                                        onChange={handleChange}
                                                        value={formData.groupement.id}
                                                    >
                                                        <option value=""></option>
                                                        {groupement.map((result, index) => (
                                                            <option
                                                                key={index}
                                                                value={result.id}
                                                            >
                                                                {result.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="group">
                                                    <button type="submit" className="btn btn-block add-circle">Créer un
                                                        administrateur
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                            <div key="setting-admin" className="card-new-publication">
                                <div className="card-header">
                                    <h2 className={'no-arrow'} onClick={handleSettingAdminHeaderClick}>
                                        <img
                                            src={setting}
                                            style={{
                                                width: 32,
                                                height: 32,
                                                filter: "grayscale(1) brightness(0.5)",
                                            }}
                                            alt="logo publication"/>
                                        Gestion des administrateurs
                                    </h2>
                                </div>
                                <div>
                                    {isSettingAdminOpen && (
                                        <>
                                            <div className="modal-table-container">
                                                <table>
                                                    <tbody>
                                                    {admins.map((result, index) => (
                                                        <tr key={index}>
                                                            <td>{result.email}</td>
                                                            <td>{result.groupement?.name}</td>
                                                            <td>{result.quotaIA}</td>
                                                            <td>{result.requestIA}</td>
                                                            <td>
                                                                <button
                                                                    className={"btn btn-false"}
                                                                    onClick={() => handleResetPassword(result.email)}
                                                                ><img
                                                                    src={resetPassword}
                                                                    style={{
                                                                        width: 15,
                                                                        height: 15,
                                                                    }}
                                                                    alt="logo reset password"/> Réinitialisation MDP
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className={"btn btn-red"}
                                                                    onClick={() => handleDeleteAdmin(result.id)}
                                                                >
                                                                    <img
                                                                    src={deleteIcon}
                                                                    style={{
                                                                        width: 10,
                                                                        height: 10,
                                                                    }}
                                                                    alt="logo delete"/> Supprimer
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPage={totalPage}
                                                previous={() => setCurrentPage(p => p - 1)}
                                                next={() => setCurrentPage(p => p + 1)}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            <div key="setting-group" className="card-new-publication">
                                <div className="card-header">
                                    <h2 className={'no-arrow'} onClick={handleSettingGroupHeaderClick}>
                                        <img
                                            src={setting}
                                            style={{
                                                width: 32,
                                                height: 32,
                                                filter: "grayscale(1) brightness(0.5)",
                                            }}
                                            alt="logo publication"/>
                                        Gestion des groupements
                                    </h2>
                                </div>
                                <div>
                                    {isSettingGroupOpen && (
                                        <div className="modal-table-container">
                                            <table>
                                                <tbody>
                                                {groupement.map((result, index) => (
                                                    <tr key={index}>
                                                        <td>{result.name}</td>
                                                        <td>
                                                            <button
                                                                className={"btn btn-red"}
                                                                onClick={() => handleDelete(result.id)}
                                                            ><img
                                                                src={deleteIcon}
                                                                style={{
                                                                    width: 10,
                                                                    height: 10,
                                                                }}
                                                                alt="logo delete"/> Supprimer
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Administration;