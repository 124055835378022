import React, {useCallback, useEffect, useState} from "react";
import next from "../../../assets/sso/next.svg";
import time from "../../../assets/sso/time.svg";
import see from "../../../assets/sso/see.svg";
import noPublication from "../../../assets/sso/no_publication.svg";
import { format } from 'date-fns';
import axios from "axios";
import mediaUrl from "../../../service/mediaUrl";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

interface Publication {
    media?: string;
    extension?: string;
    title: string;
    content: string;
    status: string;
    createdAt: string;
    publicationDate: string;
    createdByAdmin: string;
    createdByUser: string;
    id: number;
}
const NextPublication: React.FC = () => {

    const [publication, setPublication] = useState<Publication[]>([]);
    const userId = useSelector((state: RootState) => state.user.id);
    const today = new Date();
    const formattedDate = format(today, 'yyyy-MM-dd');

    const nextPublication = useCallback(async () => {
        let combinedPublications: Publication[] = [];
        const urlByUser = `/api/publications?page=1&itemsPerPage=3&status=1&createdByUser=${userId}&publicationDate[after]=${formattedDate}`;
        const globalUrl = `/api/publications?page=1&itemsPerPage=3&status=1&isGlobal=true&publicationDate[after]=${formattedDate}`;
        const globalResponse = await axios.get(globalUrl);
        combinedPublications = combinedPublications.concat(globalResponse.data["hydra:member"]);
        const responseUser = await axios.get(urlByUser);
        combinedPublications = combinedPublications.concat(responseUser.data["hydra:member"]);
        const uniquePublications = Array.from(new Map(combinedPublications.map(pub => [pub.id, pub])).values());
        const sortedPublications = uniquePublications.sort((a, b) => new Date(a.publicationDate).getTime() - new Date(b.publicationDate).getTime());
        setPublication(sortedPublications.slice(0, 1));
    }, [formattedDate, userId]);

    const formatDate = (date: string): string => {
        const publiDate = new Date(date);
        return format(publiDate, 'dd/MM/yyyy');
    }

    const formatHour = (date: string): string => {
        const publiDate = new Date(date);
        return format(publiDate, 'HH:mm');
    }

    useEffect(() => {
        nextPublication();
    }, [nextPublication]);

    return <div className={"box-next"}>
        <div className="box-title">
            <img src={next} alt="archive icon"/>
            <div className="title">Publications à venir</div>
        </div>
        {publication.length > 0 ? (
            <>
                <div className="img-next-publication-box">
                    <img src={mediaUrl(publication[0])} className="next-publication-img"
                         alt="illustration de la publication"/>
                </div>
                <div className={"body-next-publication"}>
                    <div className="next-publication-title">
                        <div className="body-title">{publication[0].title}</div>
                    </div>
                    <div className="next-publication-content">
                        <div className="body-text">{publication[0].content}</div>
                    </div>
                </div>
                <div className="next-publication-border"></div>
                <div className="bottom-next-publication">
                    <span className="date-publication">
                        <img src={time} alt="clock logo"/>
                        {formatDate(publication[0].publicationDate) + ' | ' + formatHour(publication[0].publicationDate)}
                    </span>
                    <span className="show-publication">
                        <img src={see} alt="check logo"/>
                        <Link to={`/show_publication/${publication[0].id}`}>
                            Voir un aperçu
                        </Link>
                    </span>
                </div>
            </>
        ) : (
            <div className="no-publication-show">
                <img src={noPublication} alt="no publication available"/>
                <p>Aucune publication à venir !</p>
            </div>
        )}
    </div>
}

export default NextPublication;