import React, {useState, useEffect, useMemo} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Link } from "react-router-dom";
import { ColDef } from 'ag-grid-community';
import { handleExportClick } from "../../service/csvExporter";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from "axios";
import Navbar from "../../navbar/Navbar";
import logoFb from "../../assets/logofb.svg";
import logoInsta from "../../assets/logoinsta.svg";
import logoGmb from "../../assets/logogmb.svg";
import logoIn from "../../assets/logoin.svg";
import edit from "../../assets/editOff.svg";
import Pagination from "../../pagination/Pagination";
import Spinner from "../../spinner/Spinner";
import mediaUrl from "../../service/mediaUrl";
import {useSelector} from "react-redux";
import {RootState} from "../../rootReducer";

interface Publication {
    media: string
    title: string;
    content: string;
    status: string;
    createdAt: string;
}

const statusCellRenderer = (params: any) => {
    return (
        <>
            <div className={ 'ag-cell-container' }>
                <span className={ 'ag-cell-status archive-status-' + params.data.status }>
                    {params.data.status === 0 ? "ARCHIVÉE" : "ERREUR"}
                </span>
            </div>
        </>
    );
}

const socialMediaCellRenderer = (params: any) => {
    const [ insta, fb, gmb, linkedin ] = params.data.social;
    const isActive = (network: any) => network ? 'active' : 'inactive';
    return (
        <>
            <img src={logoFb} alt="Facebook" className={`icon ${isActive(fb)}`} />
            <img src={logoInsta} alt="Instagram" className={`icon ${isActive(insta)}`} />
            <img src={logoGmb} alt="Google My Business" className={`icon ${isActive(gmb)}`} />
            <img src={logoIn} alt="LinkedIn" className={`icon ${isActive(linkedin)}`} />
        </>
    );
}

const publicationCard = (params: any) => {
    return (
        <>
            <div className={'card'}>
                <img src={mediaUrl(params.data)}
                     className={'img-card'} alt='illustration'/>
                <div className={'card-texts'}>
                    <div className={'card-text'}>
                        <span className={"card-text-title"}>{params.data.title}</span>
                    </div>
                    <div className={'card-text'}>
                        <span className={"card-text-content"}>{params.data.content}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

function ArchivedPublications() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [publications, setPublications] = useState<Publication[]>([]);
    const userId = useSelector((state: RootState) => state.user.id);
    const isSsoUser = useSelector((state: RootState) => state.user.isSsoUser);
    let itemsPerPage = 13;
    const url = `/api/publications`;

    async function fecthAllPublications(): Promise<Publication[]> {
        setLoading(true);
        let allPublications: Publication[] = [];
        let fetchPage = 1;
        let itemsPerPage = 500;
        let hasMore = true;

        while (hasMore) {
            const response = await axios.get(url + `?itemsPerPage=${itemsPerPage}&page=${fetchPage}&order%5BcreatedAt%5D=desc`);
            allPublications = allPublications.concat(response.data["hydra:member"]);
            const allItems = response.data['hydra:totalItems'];
            hasMore = fetchPage * itemsPerPage < allItems;
            fetchPage++;
            setCurrentPage(fetchPage);
        }
        setLoading(false);

        return allPublications;
    }

    const headers =
        [
            "id",
            "title",
            "content",
            "media",
            "link",
            "social",
            "status",
            "createdAt",
            "date",
        ];

    const onExportArchive = () => {
        handleExportClick(
            headers,
            fecthAllPublications,
            "archived.csv",
            () => setLoading(true),
            () => setLoading(false)
        );
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                let userUrl = '';

                if (isSsoUser) {
                    userUrl = `&createdByUser=${userId}`;
                } else {
                    userUrl = `&createdByAdmin=${userId}`;
                }

                let publications: Publication[] = [];
                let itemsPerPage = 13;
                const response = await axios.get(url + `?status=0&page=${currentPage}&order%5BcreatedAt%5D=desc&itemsPerPage=${itemsPerPage}` + userUrl);
                publications = response.data["hydra:member"];
                const totalItems = response.data['hydra:totalItems'];
                setTotalPage(Math.ceil(totalItems / itemsPerPage));
                setPublications(publications);
            } catch (error) {
                console.error(
                    'Erreur lors de la récupération des publications :',
                    error
                );
            }
        }
        fetchData();
    }, [url, currentPage, itemsPerPage, isSsoUser, userId]);

    function createdAtFormatter(params: any) {
        let dateAsString = params.data.createdAt;
        let newDate = new Date(dateAsString);
        return newDate.toLocaleString('fr-FR', { day: 'numeric',  month: 'short', year: 'numeric' });
    }

    function actionButtons(params: any) {
        let id = params.data.id;
        return (
            <>
                <Link to={`/edit_publication/${id}`}><img src={edit} className={'icon'} alt="edit icon"/></Link>
            </>
        )
    }

    const columnDefs: ColDef<Publication>[] = [
        {
            headerName: 'Publication',
            cellRenderer: publicationCard,
            autoHeight: true,
            resizable: false,
            flex: 3,
        },
        {
            headerName: 'Status',
            cellRenderer: statusCellRenderer,
            resizable: false,
            flex: 1,
        },
        {
            headerName: 'Créée Le',
            field: 'createdAt',
            valueFormatter: createdAtFormatter,
            resizable: false,
            flex: 1,
        },
        {
            headerName: "Réseaux Sociaux",
            cellRenderer: socialMediaCellRenderer,
            resizable: false
        },
        {
            headerName: "Action",
            cellRenderer: actionButtons,
            resizable: false,
            flex: 1,
        },
    ];

    const containerStyle = useMemo(() =>
        ({ width: "100%", height: "-webkit-fill-available", fontFamily: 'Montserrat'}), []);

    return (
        <div className="container">
            <div className="col-4">
                <Navbar />
            </div>
            <div className="col-8">
                <div className="right-panel">
                    <div className="right-panel-container">
                        <div className="title-bar">
                            <div className="main-title">
                                <h1>Mes publications Archivées</h1>
                                <p>Retrouvez l'ensemble de vos publications archivées sur cette page</p>
                            </div>
                            <div className="main-buttons">
                                <Link className="btn btn-block btn-archive"
                                      to={'/publications'}>
                                    Mes publications
                                </Link>
                                <Link className="btn btn-block add-circle"
                                      to={'/add_publication'}>
                                    Créer une publication
                                </Link>
                            </div>
                        </div>
                        <div className={'search-bar'}>
                            <input type="text"
                                   className="search-input"
                                   placeholder="Rechercher une publication..."
                            />
                            {!isSsoUser && (
                                <div className={'search-buttons'}>
                                    <button className='csv-export' onClick={onExportArchive}
                                            disabled={loading}>Exporter
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="ag-theme-alpine"
                             style={containerStyle}>
                        <Spinner loading={loading}/>
                            <AgGridReact
                                rowData={publications}
                                columnDefs={columnDefs}
                                domLayout='autoHeight'
                                pagination={false}
                                overlayNoRowsTemplate={'Pas de données enregistrées'}
                                suppressNoRowsOverlay={!!publications}
                            />
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            totalPage={totalPage}
                            previous={() => setCurrentPage(p => p - 1)}
                            next={() => setCurrentPage(p => p + 1)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ArchivedPublications;
