import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import { AgGridReact } from "ag-grid-react";
import {ColDef} from 'ag-grid-community';
import { handleExportClick } from "../service/csvExporter";
import Navbar from "../navbar/Navbar";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from "axios";
import "./users.css";
import logoFb from "../assets/logofb.svg"
import logoInsta from "../assets/logoinsta.svg"
import logoGmb from "../assets/logogmb.svg"
import logoIn from "../assets/logoin.svg"
import { Link } from "react-router-dom";
import Pagination from "../pagination/Pagination";
import Spinner from "../spinner/Spinner";
import Modal from "../modal/Modal";
import logo from "../assets/logo_news_factory.svg";
import {useSelector} from "react-redux";
import {RootState} from "../store";

interface UserData {
    id: number;
    active: boolean;
    address?: string;
    agencyName?: string;
    email: string;
    phoneNumber?: string;
    website?: string;
    linkedin?: string;
    insta?: string;
    gmb?: string;
    fb?: string;
    pendingActivation?: boolean;
    quotaIA?: number;
    requestIA?: number;
    groupement?: string;
    groupementName?: string;
    linkedToAgency?: boolean;
}

interface GroupementData {
    id: string;
    name: string;
}

function Users() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [userData, setUserData] = useState<UserData[]>([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState(0);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [currentAgencyName, setCurrentAgencyName] = useState(null);
    const [currentEmail, setCurrentEmail] = useState(null);
    const [currentPhone, setCurrentPhone] = useState(null);
    const [currentAddress, setCurrentAddress] = useState(null);
    const [currentUserActive, setCurrentUserActive] = useState<boolean | null>(null);
    const [currentUserPending, setCurrentUserPending] = useState<boolean | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [groupementsMap, setGroupementsMap] = useState<{ [key: string]: string }>({});
    const isFetching = useRef(false);

    const url = `/api/users`;
    const urlGroupements = `/api/groupements`;
    const activeUserUrl = `/api/activeUser/`;
    let itemsPerPage = 30;
    const isAdmin = useSelector(
        (state: RootState) => state.user.isAdmin
    );

    const fetchGroupements = useCallback(async () => {
        try {
            const response = await axios.get(urlGroupements);
            const groupements = response.data["hydra:member"];
            const groupementMap: { [key: string]: string } = {};

            groupements.forEach((groupement: GroupementData) => {
                groupementMap[groupement.id] = groupement.name;
            });

            setGroupementsMap(groupementMap);
        } catch (error) {
            console.error("Erreur lors de la récupération des groupements : ", error);
        }
    }, [urlGroupements]);

    const socialMediaCellRenderer = (params: any) => {
        const { fb, insta, linkedin, gmb } = params.data;
        const isActive = (network: any) => network ? 'active' : 'inactive';

        return (
            <>
                <img src={logoFb} alt="Facebook" className={`icon ${isActive(fb)}`} />
                <img src={logoInsta} alt="Instagram" className={`icon ${isActive(insta)}`} />
                <img src={logoIn} alt="LinkedIn" className={`icon ${isActive(linkedin)}`} />
                <img src={logoGmb} alt="Google My Business" className={`icon ${isActive(gmb)}`} />
            </>
        );
    }

    const handleCheckboxClicked = (params: any) => {
        setCurrentUserId(params.data.id);
        setCurrentAgencyName(params.data.agencyName);
        setCurrentEmail(params.data.email);
        setCurrentPhone(params.data.phoneNumber);
        setCurrentAddress(params.data.address);
        setCurrentUserActive(params.data.active);
        setCurrentUserPending(params.data.pendingActivation);
        setModalOpen(true);
    }

    const toogleActivationUser = async () => {
        try {
            const newActiveStatus = !currentUserActive;
            await axios.get(activeUserUrl + `${currentUserId}`);
            setCurrentUserActive(newActiveStatus);
        } catch (error) {
            console.error(error);
        } finally {
            setModalOpen(false);
        }
    }

    const nameCellRenderer = (params: any) => {
        const agencyName = params.data.agencyName;
        return (
            <>
                <span className={'ag-cell-name'}>{agencyName}</span>
            </>
        );
    }

    const mailCellRenderer = (params: any) => {
        const email = params.data.email;
        return (
            <>
                <span className={'ag-cell-mail'}>{email}</span>
            </>
        );
    }

    const phoneCellRenderer = (params: any) => {
        const phoneNumber = params.data.phoneNumber;
        return (
            <>
                <span className={'ag-cell-phone'}>{phoneNumber}</span>
            </>
        );
    }

    const addressCellRenderer = (params: any) => {
        const address = params.data.address;
        return (
            <>
                <span className={'ag-cell-address'}>{address}</span>
            </>
        );
    }

    const checkboxRenderer = (params :any) => {
        const handleChange = (e: any) => {
            params.context.handleCheckboxClicked(params);
        };

        return (
            <div className={"checkbox-container"}>
                <input
                    type="checkbox"
                    checked={params.data.active || params.data.pendingActivation}
                    onChange={handleChange}
                    style={params.data.pendingActivation ? {opacity: 0.5} : {}}
                />
                {params.data.pendingActivation && (
                    <span className="tooltip">Activation en cours...</span>
                )}
            </div>
        );
    }

    const handleQuotaIAChange = (params: any) => {
        const { data } = params;
            axios.patch(`/api/users/${data.id}`, {
                    quotaIA: data.quotaIA,
                }, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },
                });
    }

    const defaultColDef = useMemo(() => {
        return {
            resizable: false,
            sortable: false,
            suppressMovable: true,
        }
    }, []);

    const columns: ColDef<UserData>[] = [
        {
            headerName: "Nom de l'agence",
            cellRenderer: nameCellRenderer,
            flex: 2,
        },
        { headerName: "Mail",
            cellRenderer: mailCellRenderer,
            flex: 1,
        },
        { headerName: "Téléphone",
            cellRenderer: phoneCellRenderer,
            flex: 1,
        },
        { headerName: "Adresse",
            cellRenderer: addressCellRenderer,
            flex: 1,
        },
        { headerName: "Réseaux Sociaux",
            cellRenderer: socialMediaCellRenderer,
            flex: 1,
        },
    ];

    if (isAdmin) {
        const adminColumns: ColDef<UserData>[] = [
            { headerName: "Groupement",
                field: "groupementName",
                flex: 1,
            },
            { headerName: "Requete IA",
                field: 'requestIA',
                flex: 1,
            },
            { headerName: "Quota IA",
                field: 'quotaIA',
                editable: true,
                flex: 1,
                onCellValueChanged: handleQuotaIAChange,
            },
            { headerName: "Activer News Factory",
                field: "active",
                cellRenderer: checkboxRenderer,
                cellRendererParams: {
                    context: {
                        handleCheckboxClicked: handleCheckboxClicked
                    }
                },
                cellEditor: true,
                flex: 1,
            }
        ];
        columns.push(...adminColumns);
    }

    async function fetchAllUsers(): Promise<UserData[]>
    {
        setLoading(true);
        let users: UserData[] = [];
        let fetchPage = 1;
        let itemsPerPage = 500;
        let hasMore = true;

        while (hasMore) {
            const response = await axios.get(url
                + `?page=${fetchPage}&itemsPerPage=${itemsPerPage}`);

            users = users.concat(response.data["hydra:member"]);
            setTotalItems(response.data['hydra:totalItems']);
            hasMore = fetchPage * itemsPerPage < totalItems;
            fetchPage++;
        }
        setLoading(false);

        return users;
    }

    const onUsersExport = () => {
        const headers =
            [
                "id",
                "agencyName",
                "email",
                "phoneNumber",
                "address",
                "website",
                "linkedin",
                "insta",
                "gmb",
                "fb",
                "active",
            ];
        handleExportClick(
            headers,
            fetchAllUsers,
            "users.csv",
            () => setLoading(true),
            () => setLoading(false)
        );
    }

    async function handleSearchText(text: string) {
        setSearchText(text);
        if (text.length >= 5) {
            setIsSearching(true);
        } else {
            setIsSearching(false);
        }
    }

    useEffect(() => {
        if (isFetching.current) {
            return;
        }
        const fetchData = async () => {
            isFetching.current = true;
            try {
                let response;
                if (isSearching) {
                    response = await axios.get(url
                        + `?page=${currentPage}&search=${searchText}`);
                } else {
                    response = await axios.get(url
                        + `?page=${currentPage}`);
                }

                const users = response.data["hydra:member"];
                const usersWithGroupementName = users.map((user: UserData) => ({
                    ...user,
                    groupementName: user.groupement ? groupementsMap[user.groupement.split("/").pop()!] : "N/A",
                }));

                setTotalItems(response.data['hydra:totalItems']);
                const total = Math.ceil(totalItems / itemsPerPage);
                setTotalPage(total > 0 ? total : 1);
                setUserData(usersWithGroupementName);
            } catch (error) {
                console.error("Erreur lors de la récupération des données Users depuis Api Platform");
            } finally {
                isFetching.current = false;
            }
        };
        fetchData();
        }, [currentPage, groupementsMap, isSearching, itemsPerPage, searchText, totalItems, url]);

    useEffect(() => {
        fetchGroupements();
    }, [fetchGroupements]);

    const containerStyle = useMemo(() =>
        ({ width: "100%", height: "-webkit-fill-available", fontFamily: 'Montserrat' }), []);

    return (
        <>
            <div className="container">
                <div className="col-4">
                    <Navbar />
                </div>
                <div className="col-8">
                    <div className="right-panel">
                        <div className="right-panel-container">
                            <div className="title-bar">
                            <div className="main-title">
                                <h1>Utilisateurs</h1>
                                <p>Retrouvez l'ensemble de vos utilisateurs sur cette page</p>
                            </div>
                            <div>
                                <Link className="btn btn-block add-circle"
                                      to={'/add_publication'}>Créer une publication</Link>
                            </div>
                        </div>
                            <div className={'search-bar'}>
                                <input type="text"
                                       className="search-input"
                                       placeholder="Nom de l'agence, mail, téléphone..."
                                       value={searchText}
                                       onChange={(e) => handleSearchText(e.target.value)}/>
                                <div className={'search-buttons'}>
                                    <button className='csv-export' onClick={onUsersExport}>Exporter</button>
                                </div>
                            </div>
                            <div style={containerStyle}>
                                <div className="ag-theme-alpine theme-user"
                                     style={containerStyle}>
                                    <Spinner loading={loading}/>
                                    <AgGridReact
                                        rowData={userData}
                                        defaultColDef={defaultColDef}
                                        columnDefs={columns}
                                        domLayout='autoHeight'
                                        pagination={false}
                                        overlayNoRowsTemplate={'Pas de données enregistrées'}
                                        suppressNoRowsOverlay={!!userData}
                                    />
                                </div>
                                <Pagination
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    previous={() => setCurrentPage(p => p - 1)}
                                    next={() => setCurrentPage(p => p + 1)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                    <img className='modal-logo' src={logo} alt="logo News Factory"/>
                    <span className='modal-title'>{currentUserActive || currentUserPending ? "Désactivation " : "Activation "} News Factory</span>
                    <span className='modal-subtitle'>Confirmez-vous {currentUserActive || currentUserPending ? "la désactivation de " : "l'accès "} News Factory au client suivant : </span>
                    <span className={'modal-agency-name'}>{currentAgencyName}</span>
                    <span className={'modal-subagency'}>{currentEmail}</span>
                    <span className={'modal-subagency'}>{currentPhone}</span>
                    <span className={'modal-subagency modal-address'}>{currentAddress}</span>
                    <div className="modal-actions">
                        <button className="btn btn-modal btn-modal-cancel" onClick={() => setModalOpen(false)}>Annuler</button>
                        <button className="btn btn-modal btn-modal-publish"
                                onClick={toogleActivationUser}>Je confirme
                        </button>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default Users;