import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Groupement {
    id: number;
    name: string;
    isGlobal: boolean;
}
interface UserState {
    isAuthenticated: boolean;
    isAdmin: boolean;
    id: number | null;
    email: string | null;
    agencyName: string | null;
    token: string | null;
    quota: number | null;
    role: string | null;
    isSsoUser: boolean;
    userMyId: string | null;
    groupement: Groupement | null;
    linkedToAgency: boolean;
}

const initialState: UserState = {
    isAuthenticated: !!localStorage.getItem('userToken'),
    isAdmin: localStorage.getItem('isAdmin') === 'true',
    id: localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')!, 10) : null,
    email: localStorage.getItem('userEmail'),
    agencyName: localStorage.getItem('agencyName'),
    token: localStorage.getItem('userToken'),
    quota: localStorage.getItem('quota') ? parseInt(localStorage.getItem('quota')!, 10) : null,
    role: localStorage.getItem('roles'),
    isSsoUser: localStorage.getItem('ssoUser') === 'true',
    userMyId: localStorage.getItem('userMyId'),
    groupement: localStorage.getItem('groupement') ? JSON.parse(localStorage.getItem('groupement')!) : null,
    linkedToAgency: localStorage.getItem('linkedToAgency') === 'true',
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<{
            isAuthenticated: boolean,
            isAdmin: boolean,
            id: number,
            email: string,
            agencyName: string,
            token: string,
            quota: number,
            role: string | null,
            isSsoUser: boolean,
            userMyId: string | null,
            groupement: Groupement | null,
            linkedToAgency: boolean,
        }>) => {
            state.isAuthenticated = true;
            state.isAdmin = action.payload.isAdmin;
            state.id = action.payload.id;
            state.email = action.payload.email;
            state.agencyName = action.payload.agencyName;
            state.token = action.payload.token;
            state.quota = action.payload.quota;
            state.role = action.payload.role;
            state.isSsoUser = action.payload.isSsoUser;
            state.userMyId = action.payload.userMyId;
            state.groupement = action.payload.groupement;
            state.linkedToAgency = action.payload.linkedToAgency;

            localStorage.setItem('userToken', action.payload.token);
            localStorage.setItem('userEmail', action.payload.email);
            localStorage.setItem('agencyName', action.payload.agencyName);
            localStorage.setItem('roles', action.payload.role || '');
            localStorage.setItem('userId', action.payload.id.toString());
            localStorage.setItem('isAdmin', action.payload.isAdmin.toString());
            localStorage.setItem('quota', action.payload.quota.toString());
            localStorage.setItem('isSsoUser', action.payload.isSsoUser.toString());
            localStorage.setItem('userMyId', action.payload.userMyId || '');
            localStorage.setItem('groupement', JSON.stringify(action.payload.groupement || ''));
            localStorage.setItem('linkedToAgency', action.payload.linkedToAgency.toString());
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.isAdmin = false;
            state.id = null;
            state.email = null;
            state.agencyName = null;
            state.token = null;
            state.quota = null;
            state.role = null;
            state.isSsoUser = false;
            state.userMyId = null;
            state.groupement = null;
            state.linkedToAgency = false;

            localStorage.removeItem('userToken');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('agencyName');
            localStorage.removeItem('roles');
            localStorage.removeItem('userId');
            localStorage.removeItem('isAdmin');
            localStorage.removeItem('quota');
            localStorage.removeItem('isSsoUser');
            localStorage.removeItem('userMyId');
            localStorage.removeItem('groupement');
            localStorage.removeItem('linkedToAgency');
        },
        initializeUser: (state) => {
            const token = localStorage.getItem('userToken');
            if (token) {
                state.isAuthenticated = true;
                state.token = token;
                state.email = localStorage.getItem('userEmail');
                state.agencyName = localStorage.getItem('agencyName');
                state.isAdmin = localStorage.getItem('isAdmin') === 'true';
                state.id = localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')!, 10) : null;
                state.quota = localStorage.getItem('quota') ? parseInt(localStorage.getItem('quota')!, 10) : null;
                state.role = localStorage.getItem('roles');
                state.isSsoUser = localStorage.getItem('isSsoUser') === 'true';
                state.userMyId = localStorage.getItem('userMyId');
                state.groupement = localStorage.getItem('groupement') ? JSON.parse(localStorage.getItem('groupement')!) : null;
                state.linkedToAgency = localStorage.getItem('linkedToAgency') === 'true';
            }
        },
    },
});

export const { setUser, logout, initializeUser } = userSlice.actions;
export default userSlice.reducer;