import React from "react";
import "../dashboard/dashboard.css";
import "./dashboardsso.css";
import { Link } from "react-router-dom";
import LastPublications from "../dashboard/components/LastPublications/LastPublications";
import Navbar from "../navbar/Navbar";
import { useSelector }  from "react-redux";
import { RootState } from "../rootReducer";
import Info from "./SsoComponents/Info/Info";
import NextPublication from "./SsoComponents/NextPublication/NextPublication";
import Networks from "./SsoComponents/Networks/Networks";
import Help from "./SsoComponents/Help/Help";

function DashboardSso() {
    const agencyName = useSelector((state: RootState) => state.user.agencyName);

    return (
        <div>
            <div className="container">
                <div className="col-4">
                    <Navbar />
                </div>
                <div className="col-8">
                    <div className="right-panel">
                        <div className="right-panel-container no-overflow">
                            <div className="title-bar title-bar-publication">
                                <div className="main-title-sso">
                                    <div className="long-box-sso">
                                        <h1>Bonjour {agencyName ? agencyName.charAt(0).toUpperCase() + agencyName.slice(1) : agencyName}</h1>
                                        <p>Bienvenue sur votre espace pour gérer vos réseaux sociaux et créer
                                            vos contenus !
                                        </p>
                                    </div>
                                    <Link className="btn btn-block add-circle"
                                          to={'/add_publication'}>Créer une publication
                                    </Link>
                                </div>
                            </div>
                                <div className="info-container">
                                    <div className="left-card">
                                        <div className="bottom-card">
                                            <LastPublications />
                                        </div>
                                    </div>
                                    <div className="right-card-sso">
                                        <div className="upper-card-sso">
                                            <Info />
                                        </div>
                                        <div className="bottom-cards">
                                            <div className="left-next-publication">
                                                <NextPublication />
                                            </div>
                                            <div className="right-sso">
                                                <div className="networks-sso">
                                                    <Networks />
                                                </div>
                                                <div className="help-card">
                                                    <Help />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardSso;