import React from "react";
import "./info.css";
import share from "../../../assets/sso/share.svg";
import ia from "../../../assets/sso/ia.svg";
import choice from "../../../assets/sso/choix.svg";
import archive from "../../../assets/sso/ico_archivage 1.svg";

const Info: React.FC = () => {
return <div className="info-sso-bloc">
    <div>
        <p className="title-info-sso info-sso">
            <span className="black-text">Grâce à<br/>
                votre espace,</span><br/>
            vous pouvez
        </p>
    </div>
    <div className="info-part">
        <img src={share} alt="share icon"/>
        <p className="info-sso">
            Gérer la diffusion<br/>
            des contenus<br/>
            Newsfactory
        </p>
    </div>
    <div className="info-part">
        <img src={ia} alt="ia icon"/>
        <p className="info-sso">
            Créer vos<br/>
            publications<br/>
            avec l’IA
        </p>
    </div>
    <div className="info-part">
        <img src={choice} alt="choice icon"/>
        <p className="info-sso">
            Sélectionner les<br/>
            réseaux de<br/>
            diffusion
        </p>
    </div>
    <div className="info-part">
        <img src={archive} alt="archive icon"/>
        <p className="info-sso">
            Consulter tous<br/>
            vos derniers<br/>
            posts
        </p>
    </div>
</div>


}

export default Info;