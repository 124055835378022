import React from "react";
import "./dashboard.css";
import { Link } from "react-router-dom";
import LastPublications from "./components/LastPublications/LastPublications";
import Navbar from "../navbar/Navbar";
import Publications from "./components/Publications/Publications";
import Users from "./components/Users/Users";
import Statistics from "./components/Statistics/Statistics";
import { useSelector }  from "react-redux";
import { RootState } from "../rootReducer";

function Dashboard() {
    const userEmail = useSelector((state: RootState) => state.user.email);
    const email = userEmail?.split('@')[0];

    return (
        <div>
            <div className="container">
                <div className="col-4">
                    <Navbar />
                </div>
                <div className="col-8">
                    <div className="right-panel">
                        <div className="right-panel-container no-overflow">
                            <div className="title-bar title-bar-publication">
                                <div className="main-title">
                                    <h1>Bonjour {email ? email.charAt(0).toUpperCase() + email.slice(1) : email}</h1>
                                        <p>Voici le résumé de vos utilisateurs et publications</p>
                                </div>
                                <div>
                                    <Link className="btn btn-block add-circle"
                                          to={'/add_publication'}>Créer une publication</Link>
                                </div>
                            </div>
                            <div className="info-container">
                                <div className="left-card">
                                    <LastPublications />
                                </div>
                                <div className="right-card">
                                    <div className="upper-card">
                                        <Publications />
                                        <Users />
                                    </div>
                                    <div className="bottom-card">
                                        <Statistics />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;