import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import "./header.css";
import logo from "../assets/logo_news_factory.svg";
import { useSelector } from "react-redux";
import { RootState } from "../rootReducer";
import Logout from "../logout/Logout";

function Header() {
    const userEmail = useSelector((state: RootState) => state.user.email);
    const isSsoUser = useSelector((state: RootState) => state.user.isSsoUser);
    const name = userEmail?.split('@')[0];
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

    useEffect(() => {
        if (!userEmail) {
            setDropdownOpen(false);
        }
    }, [userEmail]);

    return (
        <div className="app-header">
            <a href={userEmail ? "/dashboard" : "/login"}><img src={logo} className="app-logo" alt="newsfactory_logo" /></a>
            {userEmail && !isSsoUser ? (
                <div className="user-menu">
                    <button className="btn-login align-left login-dropdown"
                            onClick={toggleDropdown}>
                        <span>{name ? name.charAt(0).toUpperCase() + name.slice(1) : name}</span>
                    </button>
                    {isDropdownOpen && (
                        <div className="dropdown-menu">
                            <Logout/>
                        </div>
                    )}
                </div>

            ) : (
                !isSsoUser ? <Link className="btn-login" to={'login'}> Login </Link> : <></>
            )}
        </div>
    );
}

export default Header;